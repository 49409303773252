import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/installedPage/hero";
import { useEffect } from "react";
import { Cookies } from "react-cookie-consent";
//
function Installed() {
  useEffect(() => {
    if (!Cookies.get("installed")) {
      console.log("testing");
      Cookies.set("installed", "true", { expires: 1 });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "buyButtonClick",
        category: "Custom Button",
        action: "PDF Reader Installed",
        label: "After installing PDF Reader",
      });
    }
  }, []);
  return (
    <>
      <Seo title="Installed Page" />
      <Hero />
    </>
  );
}

export default Installed;
