import React from "react";
import styled from "styled-components";

const AlignedContainers = styled.div`
  //this grid-area is only for special components - few of them that has background image so we can stack items
  //this grid area links to homepage > container
  grid-area: 1/1;
  display: flex;
  align-content: center;
  background: transparent;
  max-width: 1440px;
  width: 90%;
  margin: 0px;
  padding: 0px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

function Container({ children }) {
  return <AlignedContainers>{children}</AlignedContainers>;
}

export default Container;
